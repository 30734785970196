import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group {{if this.hasErrors 'has-error'}}\">\n  <label title=\"{{@title}}\" class=\"control-label\" for=\"{{concat @id '_input'}}\">\n    {{@label}}\n    {{#if this.isRequired}}*{{/if}}\n  </label>\n  <input\n    value={{@value}}\n    {{on \"input\" (pick \"target.value\" @onUpdateValue)}}\n    {{on \"keyup\" this.onKeyDown}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"number\"\n    disabled={{@disabled}}\n    required={{this.isRequired}}\n    min={{this.minimum}}\n    id={{if @id (concat @id \"_input\")}}\n  />\n  {{#each @errors as |error|}}\n    <span class=\"help-block\" data-test=\"error-messages\">{{error.message}}</span>\n  {{/each}}\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>", {"contents":"<div class=\"form-group {{if this.hasErrors 'has-error'}}\">\n  <label title=\"{{@title}}\" class=\"control-label\" for=\"{{concat @id '_input'}}\">\n    {{@label}}\n    {{#if this.isRequired}}*{{/if}}\n  </label>\n  <input\n    value={{@value}}\n    {{on \"input\" (pick \"target.value\" @onUpdateValue)}}\n    {{on \"keyup\" this.onKeyDown}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"number\"\n    disabled={{@disabled}}\n    required={{this.isRequired}}\n    min={{this.minimum}}\n    id={{if @id (concat @id \"_input\")}}\n  />\n  {{#each @errors as |error|}}\n    <span class=\"help-block\" data-test=\"error-messages\">{{error.message}}</span>\n  {{/each}}\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>","moduleName":"weldnote/components/generic-numeric-field.hbs","parseOptions":{"srcName":"weldnote/components/generic-numeric-field.hbs"}});
import Component from '@glimmer/component';
import { set, get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

const ENTER_KEY = 13;

export default class GenericNumericField extends Component {

  get required() {
    return this.args.required || false;
  }

  get hasErrors() {
    return !isEmpty(this.args.errors);
  }

  get hasHelp() {
    return !isEmpty(this.args.helpText);
  }

  get isRequired() {
    return this.required;
  }

  get minimum() {
    return this.args.minimum || undefined;
  }

  @action
  onKeyDown(event) {
    if (this.args.onEnter) {
      let { keyCode } = event;
      let {
        target: { value },
      } = event;
      if (value) {
        if (keyCode === ENTER_KEY) {
          this.args.onEnter(value);
        }
      }
    }
  }
}
